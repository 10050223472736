import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

declare global {
  interface Window {
    GLOBALS: Record<string, string | undefined>;
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5e8b3a3ab64b4ed693c5713416f8c280@o283013.ingest.sentry.io/6310821',
    tracesSampleRate: 0,
    environment: window.GLOBALS?.RAFFLE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches,
        ),
      }),
    ],
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
